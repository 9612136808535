button,
.dropdown_menu_button,
.more_info_button,
.hh_button,
.handyhand_button {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.more_info_button {
  width: 220px;
  height: 48px;
  margin: 50px auto 0;
  background-color: #fff;
  border-radius: 200px;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--blue_5);
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 1.4;
  padding: 6px 16px;
  text-align: center;
  transition: text-shadow .35s cubic-bezier(.615, .19, .305, .91), border .35s cubic-bezier(.615, .19, .305, .91);
  white-space: nowrap;

  &:hover {
    background-color: var(--blue_5) !important;
    color: white;
    border: 2px solid white;
    svg {
      fill: white;
    }
  }
}

.pinploy_button_v2_gray {
  background-color: #a1a2b2;
}

.handyhand_button,
.hh_button {
  width: 220px;
  height: 56px;
  min-width: max-content;
  border-radius: 32px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
  padding: 0 10px;
  svg {
    fill: var(--blue_5);
  }
  p {
    color: white;
  }

  &.small_button {
    width: 150px;
  }

  &.tiny_button {
    width: 114px;
    height: 34px;
  }

  &.red_button {
    background-color: var(--red_2);
  }

  &.blue_button {
    background-color: var(--blue_5);
    svg {
      fill: white
    }
  }

  &.dark_blue_button {
    background-color: var(--blue_4);
  }

  &.yellow_button {
    background-color: var(--yellow_2);
  }

  &.gray_button,
  &.cancel_btn {
    background-color: var(--grey_2);
    color: var(--blue_5);
    svg {
      fill: var(--blue_5);
    }
  }

  &.green_button {
    background-color: var(--green_2);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;

    &:hover {
      box-shadow: none;
    }
  }
}

.dropdown_menu_button {
  width: 145px;
  height: 45px;
  border: 1px solid #bbc2dc;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 16px;

  .helperIcon {
    float: right;
  }

  #text {
    margin: 5px 4px 0;
  }
}
