@font-face {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../font/Raleway-Regular.ttf) format('truetype');
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Anek Latin';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../font/AnekLatin-SemiBold.ttf) format('truetype');
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Anek Latin';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../font/AnekLatin-Bold.ttf) format('truetype');
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Anek Latin';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../font/AnekLatin-ExtraBold.ttf) format('truetype');
  unicode-range: U+000-5FF;
}
