@import 'button';

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

body {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Anek Latin', sans-serif;
  color: var(--grey_6);
  p {
    font-family: 'Anek Latin', sans-serif !important;
    color: var(--grey_6);
  }
  span {
    font-family: 'Anek Latin', sans-serif;
    color: var(--grey_6);
  }
}
p, span {
  font-size: 16px;
  letter-spacing: 0.25px;
  color: var(--grey_6);
  font-weight: 300;
  font-family: 'Raleway', sans-serif;
  line-height: 1.2;
}

h1 {
  font-size: 56px;
  font-weight: 700 !important;
  span {
    font-size: 56px;
    font-weight: 700 !important;
  }
}

h2 {
  font-size: 48px;
  font-weight: 700;
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
}

h3, h4 {
  font-size: 24px;
  font-weight: bold;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

p.note {
  font-size: 12px;
}

.outer_seo_page_component_container {
  padding: 82px 72px;
  border-radius: 20px;
  width: 1440px;
  max-width: 95%;
  margin: auto;
  @media screen and (max-width: 810px) {
    padding: 20px 5% 20px;
  }
  .inner_seo_page_component_container {
    width: 1200px;
    margin: auto;
    padding: 0 72px;
    @media screen and (max-width: 1200px) {
      width: unset;
      padding: unset;
    }
  }
}

.table_of_content {
  display: block;
  position: sticky;
  top: 80px;
  width: 310px;
  min-width: 310px;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--grey_1) !important;
  z-index: 1;
  .mobile_h2{
    font-size: 20px;
    font-weight: 300;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  @media screen and (max-width: 990px) {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--grey_2);
    position: relative;
    top: unset;
  }
  .expand_table_of_content {
    display: flex;
    width: 100%;
    cursor: pointer;
    @media screen and (min-width: 990px) {
      margin-bottom: 5px;
    }
    mat-icon {
      margin-top: 2px;
      margin-left: 10px;
    }
    p {
      font-size: 20px;
    }
  }




  .content {
    @media screen and (max-width: 990px) {
      max-height: 0;
      opacity: 0;
      transition: opacity 0.5s, max-height 0.5s;
      overflow: hidden;
    }
    .item {
      font-size: 14px;
      list-style: none;
      padding: 8px 0;
      &:hover {
        cursor: pointer;
        color: var(--blue_3)
      }
    }
  }
  .isExpanded {
    opacity: 1;
    max-height: 2000px;
    @media screen and (min-width: 1000px) {
      max-height: 85vh;
      overflow: auto;
    }
  }
}

table {
  border: unset;
  border: none;
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  @media (max-width: 480px) {
    min-width: unset;
  }
}

thead tr {
  background-color: var(--grey_6);
  color: #ffffff;
  text-align: left;
}
td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
tr th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

th, td {
  padding: 12px 15px;
  border: unset;
  font-size: 18px;
  @media (max-width: 480px) {
    font-size: 16px;
  }
}

tbody tr:nth-of-type(even) {
  background-color: var(--grey_1);
}


.max_width_page_component {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 1000px) {
    padding: 0 72px;
  }
  @media screen and (max-width: 1000px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}

.grey_background {
  background-color: var(--grey_1);
}

.content_from_mongo {
  ul, figure {
    margin: 24px 0;
    overflow-y: auto;
  }
  li, p {
    margin: 8px 0;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    text-indent: 0;
    padding-top: 15px;
  }
  ol {
    margin-left: 20px;
    li {
      margin: 16px 0 0 0;
    }
  }
  ul {
    li {
      margin: 16px 0 0 32px;
    }
  }
  h2 {
    padding: 32px 0 0px;
  }
  .youtube_title {
    font-size: 28px;
    padding-top: 12px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  h3 {
    font-size: 28px;
    padding-top: 12px;
    font-weight: 700;
  }
  table {
    border: unset;
    border-collapse: collapse;
    width: 100%;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    @media (max-width: 480px) {
      min-width: unset;
    }
  }
  thead tr {
    background-color: rgb(6, 18, 86);
    color: #ffffff;
    text-align: left;
  }
  td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  tr td:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  th:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  tr th:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  th, td {
    word-break: keep-all;
    padding: 12px 15px;
    border: unset;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 16px;
      word-break: keep-all;
    }
  }
  tbody tr:nth-of-type(even) {
    background-color: var(--grey_1);
  }
}

.tables_on_gray_background {
  tr:first-child {
    background-color: rgb(6, 18, 86);
    color: #ffffff;
    text-align: left;
    p {
      color: white;
    }
  }
  tbody tr:nth-of-type(even) {
    background-color: white !important;
  }
}

.cdk-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000; /* Ensure it is on top */
  place-content: center;

}
.what_it_includes_text_mongo{
  h3 {
    font-size: 20px !important;
  }
  @media screen and (max-width: 480px) {
    h3 {
      font-size: 20px !important;
    }
  }

  ul {
    li {
      margin: 0 0 0 32px;
    }
  }
}
.vertical_mongo{
  li, p {
    font-size: 16px !important;
  }
  ul {
    li {
      text-indent: 0 !important;
    }
  }
  h3 {
    font-size: 22px !important;
    padding-top: 12px;
    font-weight: 700;
  }
}
.vertical_h2 {
  padding: 20px 0 38px;
}
.table_of_content {
  a {
    color: var(--grey_6) !important;
    &:hover {
      color: var(--blue_3) !important;
    }
  }

}

a {
  color: var(--blue_3);
}

figure.image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}


.mat-calendar-table {
  border: none !important;
}


img {
  max-width: 100%;
}

img.badge.ng-lazyloading, img.mockup.ng-lazyloading {
  width: 50px;
  height: 50px;
}

.outer_container {
  background-color: white;
}

input, textarea, button, select, a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* CHANGE THE DEFAULT BACKGROUND COLOR ON CHROME AUTOCOMPLETE*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f6f6fa inset !important;
  box-shadow: 0 0 0 30px #f6f6fa inset !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--blue_3);
}

app-header {
  .menu_icon .mat-badge-content {
    right: -5px !important;
    top: -3px !important;

    @media screen and (max-width: 320px) {
      right: 5px !important;
      top: 2px !important;
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
}

.mat-mdc-menu-panel {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-width: none !important;
  min-height: 42px !important;
  background-color: white !important;
}

@media screen and (max-width: 480px) {
  input,
  textarea {
    font-size: 100% !important;
  }
  h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 320px) {
  h3 {
    font-size: 26px;
  }
}

.full-screen-modal-all-devices {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  min-width: 100% !important;
  .dialog__container{
    width: 100% !important;
  }
  margin: unset!important;
}

.full-screen-modal-mobile {
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    min-width: 100% !important;
    height: 100vh !important;
  }
}

.full-screen-modal-mobile-less-padding {
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    min-width: 100% !important;
    height: 100vh !important;
  }
  .mat-dialog-container{
    @media screen and (max-width: 480px) {
      padding: 10px !important;
    }
  }
}

.eighty-percent-screen-modal-mobile-less-padding {
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    min-width: 100% !important;
    height: 80vh !important;
  }
  .mat-dialog-container{
    @media screen and (max-width: 480px) {
      padding: 10px !important;
    }
  }
}

.full-width-modal-mobile {
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    min-width: 100% !important;
  }
}
button.mobile_menu_item.mat-mdc-menu-item, a.mobile_menu_item.mat-mdc-menu-item{
  font-size: 18px;
  color: var(--grey_6);
  cursor: pointer;
  padding: 0px 20px;
  border-bottom: 1px solid #f6f6fa;
  height: 57px;
}

div.full-screen-modal-mobile {
  @media screen and (max-width: 480px) {
    max-height: 100vh !important;
  }
}

.mobile_nav_no_auth.ng-star-inserted {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  position: fixed !important;
  left: 0;
  top: 65px;
  z-index: 10;
}

div.create_task_dia {
  width: 31vw;
  @media screen and (max-width: 480px) {
    min-width: 100vw !important;
    min-height: 100vh !important;
  }
}
.mat-progress-bar {
  height: 8px !important;
}
.fat_bar{
  .mat-progress-bar {
    height: 20px !important;
  }
}
.mat-progress-bar-fill::after {
  background-color: #89b153 !important;
  border-radius: 5px;
}
mat-progress-bar .mat-progress-bar-buffer {
  background: #e4e8eb !important;
  border-radius: 5px;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f82e42;
  border-radius: 5px;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--yellow_2);
  border-radius: 5px;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}

.owl-nav {
  @media screen and (max-width: 900px) {
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    margin-top: -3px !important;
  }
}
.owl-item { width: 100% !important; }
.owl-stage{ display: flex; }
.dot_class{
  color: var(--blue_3);
}
.owl-dot{
  width: 80px;
  span{
    background-color: transparent !important;
  }
}
.owl-dot.active {
  .dot_class{
    text-decoration: underline;
    font-weight: bold;
  }
}
.owl-carousel{
  display: flex !important;
  flex-direction: column-reverse !important;
}

.profile_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px;
}

mat-dialog-container {
  max-height: 100vh !important;
  ::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;
  }
}

/* Matcheckbox */
mat-checkbox {
  .mat-checkbox-inner-container {
    margin: 0 8px auto 0;
  }

  .mat-checkbox-label {
    white-space: pre-line;
    cursor: pointer;

    .warning__text {
      cursor: pointer;
      color: #f82e42;
    }
  }
}

/* Dropdown */
.mat-mdc-menu-content {
  padding: 0 !important;

  .select_row {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 40px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

input,
textarea {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #a1a2b2;
  padding: 10px;
  font-size: 14px;
  color: #181818;

  &:focus {
    border-color: #009AC6;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &[type="file"] {
    opacity: 0;
  }
}

textarea {
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;
  height: 120px;
}

/* Rating */
.profile_image {
    margin: 30px 0 10px;
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
  }
  h5 {
    color: var(--grey_6);
  }

.stars_container {
  display: flex;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
  margin-top: 7px;
  mat-icon {
    color: #d5d6e1;
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: -2px;
    &.yellow {
      color: var(--yellow_2);
    }
  }
}



/*******************************************************************************
                              CUSTOM SCROLLBAR
                    (not supported in Firefox or IE/Edge)
*******************************************************************************/
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f0f0f0;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a1a2b2;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey_5);
  cursor: pointer;
}
