@import "styles/font/fonts.css";
@import 'styles/client-a';

html, body { height: 100%; }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  -webkit-overflow-scrolling: touch;
  font-family: 'Raleway', sans-serif;
}
body { margin: 0; }

.cdk-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
}
.dialog__backdrop {
  background: rgba(0, 69, 89, 0.75);
}



:root
{
  --blue_1: #00c4cc;
  --blue_2: #1B9AC6; // Buttons, background colors, icons colors
  --blue_3: #1B9AC6; // Links
  --blue_4: #061256; // DARK SELECTED
  --blue_5: #1A5681;
  --blue_6: #3DA9CF;

  --red_1: #f7babe;
  --red_2: #ec5f69; // warnings
  --red_3: #cd1825;

  --green_1: #92cf49;
  --green_2: #89b153; // Buttons, background colors, icons colors
  --green_3: #536d31; // Borders, hovers ect

  --yellow_1: #ffe0ba;
  --yellow_2: #ffb354; // Buttons, background colors, icons colors
  --yellow_3: #ed8400; // Borders, hovers ect

  --grey_1: #E9EEF6; // Page Background colors
  --grey_2: #DDE4EE; // Box borders
  --grey_3: #b1bad1; // Buttons, background colors,
  --grey_4: #a1a2b2; // icons colors, Buttons, low priority text
  --grey_5: #707070; // Body text
  --grey_6: #0D293D; // Body and important text
  --grey_7: #202020; // Background
}

/* Theme */
$my-primary: (
  50: var(--blue_2),
  100: var(--blue_2),
  200: var(--blue_2),
  300: var(--blue_2),
  400: var(--blue_2),
  500: var(--blue_2),
  600: var(--blue_2),
  700: var(--blue_2),
  800: var(--blue_2),
  900: var(--blue_2),
  A100: var(--blue_2),
  A200: var(--blue_2),
  A400: var(--blue_2),
  A700: var(--blue_2),
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$my-secondary: (
  50: #92cf49,
  100: #92cf49,
  200: #92cf49,
  300: #92cf49,
  400: #92cf49,
  500: #92cf49,
  600: #92cf49,
  700: #92cf49,
  800: #92cf49,
  900: #92cf49,
  A100: #92cf49,
  A200: #92cf49,
  A400: #92cf49,
  A700: #92cf49,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$my-red: (
  50: #f82e42,
  100: #f82e42,
  200: #f82e42,
  300: #f82e42,
  400: #f82e42,
  500: #f82e42,
  600: #f82e42,
  700: #f82e42,
  800: #f82e42,
  900: #f82e42,
  A100: #f82e42,
  A200: #f82e42,
  A400: #f82e42,
  A700: #f82e42,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);


/* Scroll Bar */
.scroll-progress-container {
  width: 100%;
  height: 8px;
  background: inherit;
  overflow: hidden;
  z-index: 5;
  background-color: white;
  position: sticky;
  @media screen and (max-width: 480px) {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
  }
}

.scroll-progress-bar {
  background: var(--blue_1);
  background: -moz-linear-gradient(left, var(--blue_1) 0%, var(--blue_3) 100%);
  background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, var(--blue_1)),
      color-stop(100%, var(--blue_3))
  );
  background: -webkit-linear-gradient(
      left,
      var(--blue_1) 0%,
      var(--blue_3) 100%
  );
  background: -o-linear-gradient(left, var(--blue_1) 0%, var(--blue_3) 100%);
  background: -ms-linear-gradient(left, var(--blue_1) 0%, var(--blue_3) 100%);
  background: linear-gradient(to right, var(--blue_1) 0%, var(--blue_3) 100%);
  width: 0%;
  height: 100vh;
}

a {
  text-decoration: none;
}
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
